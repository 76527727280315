import type { RouteRecordRaw } from 'vue-router';

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/home',
    component: () => import('src/views/portal/index.vue'),
    children: [
      {
        path: '/home',
        name: 'HomePage',
        meta: {
          title: '浙江农合数字-首页',
        },
        component: () => import('src/views/portal/home/index.vue'),
      },
      {
        path: '/about',
        name: 'AboutPage',
        meta: {
          title: '浙江农合数字-关于我们',
        },
        component: () => import('src/views/portal/about/index.vue'),
      },
    ],
  },
  {
    path: '/download',
    component: () => import('src/views/download/index.vue'),
    meta: {
      title: '浙农服2.0下载',
    },
  },
];

const redirectRoute: RouteRecordRaw = {
  path: '/',
  redirect: '/home',
};

export default [...routes, redirectRoute];
