import type { App } from 'vue';
import Antdv from 'ant-design-vue';
// import 'ant-design-vue/dist/antd.dark.less';
import '@/assets/style/index.less';
import 'static/font/index.less';

function setupAntdvCompontents(app: App<Element>) {
  app.use(Antdv);
}

function setupUI(app: App<Element>) {
  setupAntdvCompontents(app);
}

export { setupUI };
