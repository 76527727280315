import { createApp } from 'vue';
import App from './App.vue';
import { setupUI } from '#/ui';
import router, { setupRouter } from '#/router';
import store from './store';

async function bootstrap() {
  const app = createApp(App);

  setupUI(app);

  app.use(store);
  setupRouter(app);

  // app.mount('#app');
  router.isReady().then(() => app.mount('#app', true));
}

bootstrap();
