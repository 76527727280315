import type { App } from 'vue';
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';

import { parseRoutes } from '@/utils/directoryParse';
import { createGuard } from './guard/';

// 解析并引入指定目录下全部.ts后缀文件（参数2定义为false，当前项目路由设计为不解析全部子目录使可以成为一个自定义的路由树）
const routes = parseRoutes(require.context('./routes', false, /\.ts$/));

// app router
const router = createRouter({
  history: createWebHashHistory(),
  routes: routes as RouteRecordRaw[],
  strict: true,
});

// config router
function setupRouter(app: App<Element>) {
  app.use(router);
  createGuard(router);
}
export default router;
export { setupRouter };
