
  import { defineComponent, ref } from 'vue';
  import { ConfigProvider } from 'ant-design-vue';
  import zhCN from 'ant-design-vue/es/locale/zh_CN';
  import dayjs from 'dayjs';
  import 'dayjs/locale/zh-cn';

  dayjs.locale('zh-cn');
  export default defineComponent({
    components: {
      ConfigProvider,
    },
    setup() {
      const locale = ref<any>(zhCN);

      return { locale };
    },
  });
